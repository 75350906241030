<template>
  <section id="order">
    <div class="order-grid-wrapper">
      <article class="vinyl-wrapper">
        <div class="hover-info">
          <div class="product">
            <h3>Vinyl</h3>
            <h4>+ Digital-Download</h4>
          </div>
          <div class="price">
            <h5>20.-</h5>
            <p>+ 7.- schweizweiter Versand</p>
          </div>
        </div>
      </article>
      <article class="booklet-wrapper">
        <div class="hover-info">
          <div class="product">
            <h3>Booklet</h3>
            <h4>+ Digital-Download</h4>
          </div>
          <div class="price">
            <h5>10.-</h5>
            <p>+ 3.- schweizweiter Versand</p>
          </div>
        </div>
      </article>
      <article class="order-text-wrapper">
        <div class="order-text-content">
          <h3>Vinyl und Booklet</h3>
          <p>
            Du willst uns auf Vinyl mit Digital Download?<br>
            Weil du noch keinen Plattenspieler hast, willst du unser Booklet
              mit Digital Download?<br>Du suchst ein Geschenk für jemanden, den du liebst?<br><br>
            Lass uns wissen, was du brauchst!
          </p>
          <a
            href="mailto:order@mc1.ch?subject=Bestellung Vinyl / Booklet&Body=Hallo Marie et Claire%0A%0A[Deine Bestellung hier]%0A%0A[Deine Adresse hier]"
            class="bestell-btn"
          >
            <button class="btn1 mag border-hover">Kontakt</button>
          </a>
          <a :href="`./BZ_marie&claire_2021-01-06.pdf`" target="_blank">Hier geht es zum BZ Artikel zu unserem Album!</a>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: "Order",
};
</script>

<style lang="scss" scoped>
.order-grid-wrapper {
  min-height: 85vh;
  display: grid;
  grid-template-areas:
    "a a a c c"
    "b b b c c";
  h3 {
    color: #a31781;
    font-size: 4rem;
  }
  .vinyl-wrapper,
  .booklet-wrapper {
    background-position: center;
    background-size: cover;
    &:hover .hover-info {
      opacity: 1;
    }
    .hover-info {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      background-color: rgba(171, 198, 233, 0.8);
      height: 100%;
      width: 100%;
      padding: 0vw 2vw 1vw 8vw;
      opacity: 0;
      transition: 0.3s;
      h4 {
        color: white;
      }
      .product {
        align-self: center;
      }
      .price {
        align-self: flex-end;
        color: #a31781;
        font-style: italic;
        p {
          color: #a31781;
          font-weight: 500;
        }
      }
    }
  }
  .vinyl-wrapper {
    grid-area: a;
    background-image: url(~@/assets/Vinyl_MockUp.jpg);
  }
  .booklet-wrapper {
    grid-area: b;
    background-image: url(~@/assets/Booklet_MockUp.jpg);
  }
  .order-text-wrapper {
    grid-area: c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #abc6e9;
    h3 {
      font-size: 5rem;
    }
    .order-text-content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: stretch;
      padding: 5vw 2vw 5vw 3vw;
      ul {
        font-size: 1.5rem;
        font-weight: 500;
        padding: 2rem 0 5rem 0;
        line-height: 170%;
        letter-spacing: 0.17rem;
        color: white;
        li {
          list-style-type: circle;
        }
      }
      p {
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.17rem;
      }
      .bestell-btn {
        margin: 3rem 0 5rem 0;
      }
      .snp-notice {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60rem;
        padding: 2vw 4vw 2vw 4vw;
        background: white;
        border-radius: 3px;
        margin-bottom: 5vh;
        img {
          width: 15vw;
          flex: 1;
        }
        .adress {
          flex: 2;
          a,
          p {
            font-size: 1.1rem;
            color: #a31781;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .order-grid-wrapper {
    width: 90vw;
    grid-template-areas:
      "a b"
      "c c";
    h3 {
      font-size: 3rem;
    }
    .vinyl-wrapper,
    .booklet-wrapper {
      width: 45vw;
      height: 45vw;
      .hover-info {
        flex-direction: column;
        justify-content: space-evenly;
        padding: 3vw;
        opacity: 1;
        h5 {
          font-size: 3rem;
        }
        .price {
          align-self: center;
        }
      }
    }
    .order-text-wrapper {
      padding: 5vw;
      h3 {
        font-size: 5rem;
      }
      .order-text-content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: stretch;
        padding: 1vw;
        ul {
          list-style-type: circle;
          font-size: 1.2rem;
          font-weight: 500;
          padding: 2rem 0 5rem 0;
          line-height: 170%;
          letter-spacing: 0.17rem;
          color: white;
          li {
            list-style-type: circle;
          }
        }
        p{
          font-size: 1.2rem;
        }
        .snp-notice {
          width: 80vw;
        }
      }
    }
  }
}
</style>
