<template>
  <div class="impressum">
    <impressum-content></impressum-content>
  </div>
</template>

<script>
import ImpressumContent from '@/components/Impressum/ImpressumContent.vue';
export default {
  title: 'Impressum - Marie & Claire',
  name: "Impressum",

  components:{
    ImpressumContent,
  }
};
</script>

<style lang="scss" scoped>
#impressum{
  padding-top: 10vh;
}
</style>
