<template>
  <section id="listen">
    <article class="skitzen-container">
      <img src="@/assets/listening-skitzen.svg" alt="" />
    </article>
    <article class="spotify-container">
      <iframe
        src="https://open.spotify.com/embed/album/7kFO9LIvAfItn6AoJc1sUm"
        width="400"
        height="400"
        frameborder="0"
        scrolling="no"
        allowtransparency="true"
        allow="encrypted-media"
      ></iframe>
    </article>
  </section>
</template>

<script>
export default {
  name: "Bio",
};
</script>

<style lang="scss" scoped>
#listen {
  display: flex;
  .skitzen-container {
    display: flex;
    background: #a31781;
    width: 60vw;
    height: 70vh;
    padding: 2vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .spotify-container {
    align-self: flex-end;
    width: 50vw;
    height: 70vh;
    overflow: hidden;
    iframe {
      width: 100%;
      height: 100%;
      background: #a31781;
    }
  }
}
@media only screen and (max-width: 1024px) {
  #listen {
    .skitzen-container {
      display: none;
    }
    .spotify-container {
      width: 90vw;
      height: 75vh;
      padding-top: 15vw;
    }
  }
}
</style>
