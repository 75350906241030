<template>
  <section id="bio">
    <article class="band-foto"></article>
    <article class="bio-text">
      <h2>MARIE & CLAIRE</h2>
      <article class="de-bio bio-article">
        <strong>de</strong>
        <p>
          Die beiden Berner Songwriter Fankhauser und Kunz sind seit Jugendtagen dem Musikschaffen zugetan. Als MARIE & CLAIRE treten sie seit rund einem Viertel Jahrhundert gemeinsam auf.

          <span v-show="!readMoreDE">. .</span> <br /><span v-show="readMoreDE">
            Sie schreiben selten perfekte Musik, dafür ehrliche Geschichten mit intimen Ecken und Kanten. Wenn einer gelingt, dann haben Kunz und Fankhauser viel Freude. Und um genau das geht es ihnen.<br><br>

Seit Sommer 2022 haben MARIE & CLAIRE noch mehr Freude. Nufer und Oppliger sind dazu gestossen. Die Songs werden kräftiger, die Texte mutiger. Ihre Songs sind um die 
drei Minuten lang. Pop de Berne.
          </span>
        </p>
        <a class="more-click" @click="toggleTextDE()" :value="{}">{{
          btnDE
        }}</a>
      </article>
      <article class="fr-bio bio-article">
        <strong>fr</strong>
        <p>
          Les deux chansonniers bernois Fankhauser et Kunz se consacrent à la musique depuis leur enfance. Sous le nom de MARIE & CLAIRE, ils se produisent ensemble depuis près d'un quart de siècle.

          <span v-show="!readMoreFR">. .</span> <br /><span v-show="readMoreFR">
            Ils écrivent une musique sensible et rarement parfaite avec des recoins intimes. Quand l'une d'entre elles est réussie, Kunz et Fankhauser se réjouissent. Et c'est exactement ce qui les intéresse. <br><br>

Depuis l'été 2022, MARIE & CLAIRE ont encore plus de plaisir. Nufer et Oppliger les ont rejoints. Les chansons sont encore plus fortes, les textes encore plus courageux. Leurs chansons sont d'une durée de trois minutes. Pop de Berne. 
          </span>
        </p>
        <a class="more-click" @click="toggleTextFR()" :value="{}">{{
          btnFR
        }}</a>
        <p><br><br>Peter Fankhauser, Vocals & Guitar | Daniel Kunz, Guitar & Vocals |
Lorenz Nufer, Drums & Vocals | Tinu Oppliger, Bass </p>
      </article>
    </article>
  </section>
</template>

<script>
export default {
  name: "Bio",
  data() {
    return {
      readMoreDE: false,
      readMoreFR: false,
      btnDE: "mehr lesen",
      btnFR: "lire la suite",
    };
  },
  methods: {
    toggleTextDE() {
      this.readMoreDE = !this.readMoreDE;
      this.readMoreDE
        ? (this.btnDE = "weniger lesen")
        : (this.btnDE = "mehr lesen");
    },
    toggleTextFR() {
      this.readMoreFR = !this.readMoreFR;
      this.readMoreFR
        ? (this.btnFR = "lire moins")
        : (this.btnFR = "lire la suite");
    },
  },
};
</script>

<style lang="scss" scoped>
#bio {
  display: flex;
  z-index: 30 !important;
  .band-foto {
    background-image: url(~@/assets/marie&claire_bandfoto.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-position: center;
    width: 50vw;
    height: 35vw;
    flex: none;
    z-index: 10;
  }
  .bio-text {
    background: #a31781;
    color: white;
    padding: 8vw 4vw 3vw 6vw;
    width: 70vw;
    margin-left: -40vw;
    margin-top: 30vw;
    .more-click {
      color: #abc6e9;
      cursor: pointer;
      font-style: italic;
      font-weight: 500;
      letter-spacing: 0.1rem;
    }
    h3 {
      color: #abc6e9;
      padding-bottom: 0.8rem;
    }
    .bio-article {
      padding-top: 2rem;
    }
  }
}
@media only screen and (max-width: 1024px) {
  #bio {
    flex-direction: column;
    .band-foto {
      width: 90vw;
      height: 40vh;
    }
    .bio-text {
      margin: 0px;
      width: 90vw;
      padding: 5vw;
    }
  }
}
</style>
