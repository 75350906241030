<template>
  <div class="HeaderVue">
    <header>
      <ul class="nav-links" v-bind:class="{ navactive: toggleNav }">
        <a class="rou-li" @click="navSlide()" href="/#landing" v-smooth-scroll
          >home</a
        >
        <a class="rou-li" @click="navSlide()" href="/#listen" v-smooth-scroll
          >songs</a
        >
        <a class="rou-li" @click="navSlide()" href="/#bio" v-smooth-scroll
          >bio</a
        >
        <a class="rou-li" @click="navSlide()" href="/#order" v-smooth-scroll
          >order</a
        >
        <router-link
          class="rou-li"
          @click.native="navSlide()"
          :to="{ path: '/press' }"
          v-smooth-scroll
          >press</router-link
        >
        <a class="rou-li" @click="navSlide()" href="mailto:info@mc1.ch">kontakt</a>
      </ul>
      <div
        class="burger"
        @click="navSlide()"
        v-bind:class="{ toggle: toggleNav }"
      >
        <div class="line1"></div>
        <div class="line2"></div>
        <div class="line3"></div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderVue",
  data() {
    return {
      toggleNav: false,
    };
  },
  methods: {
    navSlide() {
      this.toggleNav = !this.toggleNav;
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  display: flex;
  width: 100vw;
  min-height: 10vh;
  margin: 0;
  align-items: center;
  background: transparent;
  position: fixed;
  justify-content: flex-end;
  z-index: 100;
  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
    padding: 10vh 0 10vh 0;
    position: fixed;
    background-color: #a31781;
    text-align: left;
    right: 0em;
    height: 100vh;
    width: 35vw;
    top: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in;
    .rou-li {
      font-size: 3rem;
      letter-spacing: 0.2rem;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      color: white;
      &:hover {
        color: #abc6e9;
      }
    }
  }
  .navactive {
    transform: translateX(0%);
  }
  .burger {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: 13vw;
    height: 10vw;
    div {
      width: 4.5rem;
      height: 0.6rem;
      margin: 0.3rem;
      background-color: white;
      transition: all 0.5s ease;
      -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
  .toggle {
    div {
      background: white;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    }
    .line1 {
      transform: rotate(-45deg) translate(-0.7rem, 1rem);
      transition: 0.3s ease;
      max-width: 4rem;
    }
    .line2 {
      opacity: 0;
    }
    .line3 {
      transform: rotate(45deg) translate(-0.7rem, -1rem);
      max-width: 4rem;
    }
  }

  .space {
    flex: 2;
  }
  .space2 {
    flex: 2;
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  header {
    padding-right: 10vw;
    background: #a31781;
    .nav-links {
      width: 100vw;
    }
    .burger {
      div {
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
      }
    }
  }
}
</style>
