import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vueSmoothScroll from "vue-smooth-scroll";
import titleMixin from "./mixins/titleMixin";

Vue.use(vueSmoothScroll, { duration: 1000, updateHistory: false });

Vue.config.productionTip = false;

Vue.mixin(titleMixin);

new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");
