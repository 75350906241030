<template>
  <div id="impressum-cont">
    <h1>impressum</h1>
    <section>
      <article>
        <h3>inhaltliche verantwortung</h3>
        <p>
          <strong>MARIE & CLAIRE</strong><br />Daniel Kunz
          <br />Kirchlindachstrasse 15 <br />
          3052 Zollikofen
        </p>
        <a href="mailto:web@mc1.ch">web@mc1.ch</a>
        <p>
          Bilder und Design von <a href="https://ocular.ch">ocular.ch</a>. <br />
          Coding der Website von Jonas Kunz.
        </p>
      </article>
      <article>
        <h3>COPYRIGHT</h3>
        <p>
          Das Copyright für sämtliche Inhalte dieser Website liegen bei MARIE &
          CLAIRE.
        </p>
      </article>
      <article>
        <h3>DISCLAIMER</h3>
        <p>
          Alle Texte und Links wurden sorgfältig geprüft und werden laufend
          aktualisiert. Wir sind bemüht, richtige und vollständige Informationen
          auf dieser Website bereitzustellen, übernehmen aber keinerlei
          Verantwortung, Garantien oder Haftung dafür, dass die durch diese
          Website bereitgestellten Informationen, richtig, vollständig oder
          aktuell sind. Wir behalten uns das Recht vor, jederzeit und ohne
          Vorankündigung die Informationen auf dieser Website zu ändern und
          verpflichten uns auch nicht, die enthaltenen Informationen zu
          aktualisieren. Alle Links zu externen Anbietern wurden zum Zeitpunkt
          ihrer Aufnahme auf ihre Richtigkeit überprüft, dennoch haften wir
          nicht für Inhalte und Verfügbarkeit von Websites, die mittels
          Hyperlinks zu erreichen sind. Für illegale, fehlerhafte oder
          unvollständige Inhalte und insbesondere für Schäden, die durch Inhalte
          verknüpfter Seiten entstehen, haftet allein der Anbieter der Seite,
          auf welche verwiesen wurde. Dabei ist es gleichgültig, ob der Schaden
          direkter, indirekter oder finanzieller Natur ist oder ein sonstiger
          Schaden vorliegt, der sich aus Datenverlust, Nutzungsausfall oder
          anderen Gründen aller Art ergeben könnte.
        </p>
      </article>
      <article>
        <h3>DATENSCHUTZ</h3>
        <p>
          Für die Sicherheit der Datenübertragung im Internet können wir keine
          Gewähr übernehmen, insbesondere besteht bei der Übertragung von Daten
          per E-Mail die Gefahr des Zugriffs durch Dritte. <br /><br />

          Einer Nutzung der im Impressum veröffentlichten Kontaktdaten durch
          Dritte zu Werbezwecken wird hiermit ausdrücklich widersprochen. Der
          Betreiber behält sich für den Fall unverlangt zugesandter Werbe- oder
          Informationsmaterialien ausdrücklich rechtliche Schritte vor.
          <br /><br />

          Sollten einzelne Regelungen oder Formulierungen dieses
          Haftungsausschlusses unwirksam sein oder werden, bleiben die übrigen
          Regelungen in ihrem Inhalt und ihrer Gültigkeit hiervon unberührt.
        </p>
        <h4>Spotify</h4>
        <p>
          Auf unseren Seiten sind Funktionen des Musik-Dienstes Spotify
          eingebunden. Anbieter ist die Spotify AB, Birger Jarlsgatan 61, 113 56
          Stockholm in Schweden. Die Spotify Plugins erkennen Sie an dem grünen
          Logo auf unserer Seite. Eine Übersicht über die Spotify-Plugins finden
          Sie unter: https://developer.spotify.com. <br /><br />

          Dadurch kann beim Besuch unserer Seiten über das Plugin eine direkte
          Verbindung zwischen Ihrem Browser und dem Spotify-Server hergestellt
          werden. Spotify erhält dadurch die Information, dass Sie mit Ihrer
          IP-Adresse unsere Seite besucht haben. Wenn Sie den Spotify Button
          anklicken während Sie in Ihrem Spotify-Account eingeloggt sind, können
          Sie die Inhalte unserer Seiten auf Ihrem Spotify Profil verlinken.
          Dadurch kann Spotify den Besuch unserer Seiten Ihrem Benutzerkonto
          zuordnen. <br /><br />

          Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
          DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der
          ansprechenden akustischen Ausgestaltung seiner Website. <br /><br />

          Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
          von Spotify: https://www.spotify.com/de/legal/privacy-policy/.
          <br /><br />

          Wenn Sie nicht wünschen, dass Spotify den Besuch unserer Seiten Ihrem
          Spotify-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
          Spotify-Benutzerkonto aus.
        </p>
      </article>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  margin: 0px;
}
#impressum-cont {
  min-height: 100vh;
  background: white;
  padding-top: 15vh;
  p {
    color: black;
    font-size: 1.6rem;
    padding: 1vh 0 1vh 0;
    strong{
      color: black;
    }
  }
  a{
    color: black;
  }
  h3 {
    text-transform: uppercase;
    color: #a31781;
    font-size: 4rem;
  }
  h4 {
    color: #a31781;
    font-size: 2.7rem;
    padding-top: 2vh;
  }
  article{
    padding: 0 0 2vh 0;
  }
}
</style>
