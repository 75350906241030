<template>
  <div class="home">    
    <landing-section></landing-section>
    <listening-section></listening-section>
    <bio-section></bio-section>
    <order-section></order-section>
  </div>
</template>

<script>
import LandingSection from "@/components/Landing.vue";
import ListeningSection from "@/components/Listen.vue";
import BioSection from "@/components/Bio.vue";
import OrderSection from "@/components/Order.vue";

export default {
  title: 'Home - Marie & Claire',
  name: "Home",
  components: {
    ListeningSection,
    LandingSection,
    BioSection,
    OrderSection
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
header{
  position: fixed;
}
</style>
