<template>
  <div id="app">
    <nav-vue></nav-vue>
    <router-view />
    <footer-vue></footer-vue>
  </div>
</template>
<script>
import FooterVue from "@/components/Footer.vue";
import NavVue from "@/components/Nav.vue";

export default {
  components: {
    NavVue,
    FooterVue,
  },
};
</script>

<style lang="scss">
@import url("https://use.typekit.net/dbr4gii.css");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -msbox-sizing: border-box;
  overflow-x: hidden;
}
html,
body {
  width: 100%;
  font-size: 62.5%;
  font-family: neue-haas-grotesk-display, "Arial", Helvetica, sans-serif;
  background-image: url(~@/assets/body-background-light.jpg);
  background-position: center;
  background-attachment: fixed;
}
body {
  -webkit-overflow-scroll: touch;
  background-color: white;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
::selection {
  background-color: black;
  color: white;
}
section {
  width: 100%;
  padding: 10vw;
  padding-top: 15vh;
  padding-bottom: 5vh;
  justify-content: center;
}
/* fonts */
h1 {
  font-family: canada-type-gibson, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 6rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.2rem;
  color: #a31781;
}
h2 {
  text-transform: uppercase;
  font-size: 3rem;
}
h3 {
  font-weight: 800;
  font-size: 4rem;
  letter-spacing: 0.2rem;
  padding-top: 0.8rem;
  text-align: left;
}
h4 {
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.18rem;
}
h5 {
  font-size: 3rem;
}
strong {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #abc6e9;
}
p {
  line-height: 150%;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 0.16rem;
  color: white;
}
a {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.16rem;
  outline: none;
  color: #a31781;
  cursor: pointer;
  text-decoration: underline;
}
button {
  border: none;
  outline: none;
  color: white;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  transition: 0.2s;
}
.btn1 {
  width: 20rem;
  height: 5rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.1rem;
}
.mag {
  background-color: #a31781;
}
.border-hover-mag {
  &:hover {
    background: transparent;
    border: 2px #a31781 solid;
    color: #a31781;
  }
}
.color-hover-blue {
  &:hover {
    background: #abc6e9;
  }
}
.blue {
  background-color: #abc6e9;
  &:hover {
    color: #a31781;
  }
}

@media only screen and (max-width: 1024px){
  section{
    padding: 5vw;
  }
  h1{
    font-size: 4rem;
  }
  p, a{
    font-size: 1.2rem;
  }
}
</style>
