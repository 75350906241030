import { render, staticRenderFns } from "./ImpressumContent.vue?vue&type=template&id=3cd4acd7&scoped=true"
import script from "./ImpressumContent.vue?vue&type=script&lang=js"
export * from "./ImpressumContent.vue?vue&type=script&lang=js"
import style0 from "./ImpressumContent.vue?vue&type=style&index=0&id=3cd4acd7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cd4acd7",
  null
  
)

export default component.exports