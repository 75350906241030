<template>
  <div class="FooterVue">
    <footer>
      <div class="footlist">
        <ul>
          <li><strong>social media</strong></li>
          <li>
            <a
              target="_blank"
              href="https://www.instagram.com/marieclaire_band/"
              >Instagram</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UC2rQp344A1JQnwYTzK1a5_w"
              >YouTube</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://open.spotify.com/artist/0zy2bWgXaLfKOlDpXcLDc2?si=90CFKcSETFqNGX3H9GEJyg"
              >Spotify</a
            >
          </li>
          <li>
            <a target="_blank" href="https://www.facebook.com/popupsongs/"
              >Facebook</a
            >
          </li>
        </ul>
        <ul>
          <li><strong>Marie & Claire</strong></li>
          <li>
            <a class="rou-li" @click="navSlide()" href="/#bio" v-smooth-scroll
              >Bio</a
            >
          </li>
          <li>
            <router-link
              class="rou-li"
              @click.native="navSlide()"
              :to="{ path: '/press' }"
              v-smooth-scroll
              >Press</router-link
            >
          </li>
        </ul>
      </div>

      <div class="footbottom">
        <a class="a" href="https://ocular.ch">Webdesign by Jouns</a>
        <router-link class="b" :to="{ path: '/Impressum' }" v-smooth-scroll
          >Impressum</router-link
        >
        <p class="c">&copy; 2023 MARIE & CLAIRE</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterVue",
};
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  flex-direction: column;
  color: #808880;
  background-color: #abc6e9;
  padding: 5vh;
  ul {
    list-style: none;
    line-height: 1.5;
    strong {
      color: #a31781;
      font-size: 2rem;
      text-transform: capitalize;
      font-weight: 600;
    }
  }
  a,
  p {
    color: white;
    font-size: 1.3rem;
    text-decoration: none;
    letter-spacing: 0.05rem;
    padding: 0.2rem;
    display: inline-block;
    font-weight: 500;
    font-size: 1.5rem;
    padding: 0;
  }
  a:hover {
    color: #a31781;
  }
  .logo-container {
    align-items: center;
    padding-top: 4rem;
    .logo {
      width: 10rem;
      fill: #808080;
      &:hover {
        fill: #f5f4f3;
      }
    }
  }
  .footlist {
    display: flex;
    justify-content: space-around;
    text-align: left;
    padding: 5rem 15rem 3rem 15rem;
    margin-top: 0rem;
  }
  .footbottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0 3rem 0;
    a,
    p {
      font-size: 1.3rem;
      color: #a31781;
      padding: 0 10rem 0 10rem;
    }
  }
}
.bwf {
  background: #abc6e9;
}

@media only screen and (max-width: 1024px) {
  footer {
    padding: 7vh 0rem 0 0;
    .footlist {
      display: grid;
      grid-template-columns: 35vw 35vw;
      grid-template-rows: inherit;
      grid-gap: 2vw;
      justify-content: center;
      padding: 0;
      margin-top: 0rem;
      ul {
        display: flex;
        flex-direction: column;
        li {
          strong {
            font-size: 1.5rem;
          }
          a, p{
            font-size: 1.2rem;
          }
        }
      }
    }
    .footbottom {
      display: grid;
      p,
      a,
      router-link {
        padding: 1rem;
        font-size: 1rem;
      }
    }
    p,
    a {
      padding: 0 2rem 0 0;
    }
  }
}
</style>
